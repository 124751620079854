===================
Letstream Input PAN Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - pan (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
        <vs-input 
            v-model="value"
            :warning="warning ? true : false"
            :warning-text="warning_text ? warning_text : null"
            :label="label"
            :success="is_success"
            :danger="is_invalid"
            class="w-full"
            :success-text="successText"
            :danger-text="dangerText"
            :placeholder="placeHolder"
            :disabled="disabled"
        />
</template>

<script>
export default {
    props: {
        pan : {
            required: true
        },
        label : {
            default: "Permanent Account Number (PAN)",
        },
        successText : {
            default: null,
        },
        dangerText : {
            default: null,
        },
        placeHolder : {
            default: 'Enter your 10 digit PAN',
        },
        warning : {
            type: Boolean,
            default: false,
        },
        warning_text : {
            type: String,
            default: 'PAN is invalid'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            reg: /^([A-Za-z]{5})([0-9]{4})([A-Za-z]{1})$/,
            is_success : null,
            is_invalid : null,
            value: this.pan
        }
    },
    mounted() {
        this.value = this.pan
    },
    watch: {
        pan: function (value, oldV) {
            if(value == oldV) return
            this.value = value
        },
        value: function(value, oldV){
            if(value == oldV) return

            if(this.reg.test(value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
            }
            this.$emit('update:pan', value)
        }
    }
}
</script>