===================
Letstream Input Address Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - address_line_1 (Required): Sate to save given input
 - address_line_2 (Required): Sate to save given input
 - city (Required): Sate to save given input
 - selectedState (Required): Sate to save given input
 - selectedDistrict (Required): Sate to save given input
 - label_state (Required)
 - label_district (Required)
 - successText_state (Required): Text message for correct input
 - successText_district (Required): Text message for correct input
 - dangerText_state (Required): Text message for incorrect input
 - dangerText_district (Required): Text message for incorrect input
 - placeHolder_address_line_1 (Required)
 - placeHolder_address_line_2 (Required)
 - placeHolder_city (Required)
 - placeHolder_state (Required)
 - placeHolder_district (Required)

====================


<template>
    <div :class="container_class">
        <vs-input 
            v-model="value_address_line_1"
            :label="label_address_line_1"
            :class="class_custom_address_line_1"
            :placeholder="placeHolder_address_line_1"
            :disabled="disabled"
        />
        <vs-input 
            v-model="value_address_line_2"
            :class="class_custom_address_line_2"
            :label="label_address_line_2"
            :placeholder="placeHolder_address_line_2"
            :disabled="disabled"
        />
        <vs-input 
            v-model="value_city"
            :label="label_city"
            :class="class_custom_city"
            :placeholder="placeHolder_city"
            :disabled="disabled"
        />
        <vs-input 
            v-model="value_pincode"
            :label="label_pincode"
            :class="class_custom_pincode"
            :placeholder="placeHolder_pincode"
            type="number"
            :disabled="disabled"
        />
        <StateAndDistrictSelectMenu
            v-bind:state.sync="value_state"
            v-bind:district.sync="value_district"
            :label_state="label_state"
            :label_district="label_district"
            :class_state="class_custom_state"
            :class_district="class_custom_district"
            :container_class="class_state_district_container"
            :disabled="disabled"
        />
        <label class="vs-input--label pl-5">{{ label_address_proof_file }}</label>
        <FileSelection 
            :class="class_custom_address_proof_file"
            :value.sync="value_address_proof_file"
            :extensions="['jpg', 'pdf', 'png', 'jpeg', 'zip']"
            :readonly="disabled"
        />
    </div>
</template>

<script>
export default {
    props: {
        container_class: {
            default: null
        },
        class_custom_address_line_1 : {
            default: 'w-full',
        },
        class_custom_address_line_2 : {
            default: 'w-full',
        },
        class_custom_city : {
            default: 'w-full',
        },
        class_custom_pincode : {
            default: 'w-full',
        },
        class_custom_state : {
            default: 'w-full',
        },
        class_custom_district : {
            default: 'w-full',
        },
        class_state_district_container: {
            default: null,
        },
        class_custom_address_proof_file: {
            default: 'w-full',
        },
        address_line_1 : {
            default: '',
            required: true
        },
        address_line_2 : {
            default: '',
            required: true
        },
        city : {
            default: '',
            required: true
        },
        pincode : {
            default: '',
            required: true
        },
        selectedState : {
            default: '',
            required: true
        },
        selectedDistrict : {
            default: '',
            required: true
        },
        address_proof_file : {
            default: null,
        },
        label_address_line_1 : {
            default: 'Address Line 1',
        },
        label_address_line_2 : {
            default: 'Address Line 2',
        },
        label_city : {
            default: 'City',
        },
        label_pincode : {
            default: 'Pincode',
        },
        label_state : {
            default: 'State',
        },
        label_district : {
            default: 'District',
        },
        label_address_proof_file : {
            default: 'Address Proof',
        },
        placeHolder_address_line_1 : {
            default: 'Enter Address Line 1',
        },
        placeHolder_address_line_2 : {
            default: 'Enter Address Line 2',
        },
        placeHolder_city : {
            default: 'Enter City Name',
        },
        placeHolder_pincode : {
            default: 'Enter Pincode',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value_address_line_1: this.address_line_1,
            value_address_line_2: this.address_line_2,
            value_city: this.city,
            value_pincode: this.pincode,
            value_state: this.selectedState,
            value_district: this.selectedDistrict,
            value_address_proof_file: this.address_proof_file,
        }
    },
    watch: {
        address_line_1(value){
            this.value_address_line_1 = value
        },
        address_line_2(value){
            this.value_address_line_2 = value
        },
        city(value){
            this.value_city = value
        },
        pincode(value){
            this.value_pincode = value
        },
        selectedState(value){
            this.value_state = value
        },
        selectedDistrict(value){
            this.value_district = value
        },
        address_proof_file(value, oldV){
            if(value == oldV) return
            
            this.value_address_proof_file = value
        },
        value_address_line_1(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:address_line_1', value)
        },
        value_address_line_2(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:address_line_2', value)
        },
        value_city(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:city', value)
        },
        value_pincode(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:pincode', value)
        },
        value_state(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:selectedState', value)
        },
        value_district(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:selectedDistrict', value)
        },
        value_address_proof_file(value, oldV) {
            if(value == oldV) return
            
            this.$emit('update:address_proof_file', value)
        }
    }
}
</script>