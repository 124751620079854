<template>
    <span>
        <template v-if="format == 'file'">
            <template v-if="text">
                File Uploaded. <a :href="text">Click Here</a> to Download
            </template>
            <template v-else>
                No File Uploaded
            </template>
        </template>
        <template v-else>        
            {{ formatted_text }}
        </template>
    </span>
</template>

<script>
import moment from 'moment';
import Countries from "./countries.json";
import stateAndDistrictOptions from "./districts.json"

export default {
    name: 'ls-format-text',
    props: {
        text: {
            required: true
        },
        format: {
            required: true
        },
        kwargs: {
            required: false
        }
    },
    computed: {
        formatted_text() {
            switch(this.format) {
                case 'date':
                    return this.formatDate(this.text)
                case 'datetime':
                    return this.formatDateTime(this.text)
                case 'select': 
                    return this.formatSelect(this.text)
                case 'gender': 
                    return this.formatGender(this.text)
                case 'text':
                    if(!this.text) return "No Value Entered"
                    return this.text
                case 'boolean': 
                    return this.formatBoolean(this.text)
                case 'address':
                    return this.formatAddress(this.text)
                case 'nationality':
                    return this.formatNationality(this.text)
                case 'currency':
                    return this.formatCurrency(this.text, this.kwargs?this.kwargs.disable_decimal:false)
                case 'number':
                    return this.formatNumber(this.text, this.kwargs?this.kwargs.disable_decimal:false)
                case 'size':
                    return this.formatBytes(this.text)
                default:
                    return this.text
            }
        }
    },
    methods: {
        formatDate(date) {
            if(!date) return "No Value Entered"
            let d = date
            if(typeof date == 'string')
                d = new Date(date)
            return moment(date).format('DD MMM, YYYY')
        },
        formatDateTime(date) {
            let d = date
            if(typeof date == 'string')
                d = new Date(date)
            return moment(date).format('DD MMM, YYYY hh:mm A')
        },
        formatSelect(value) {
            if(!value) return "Not Selected"
            let v = this.kwargs.find( v => v.value == value)
            
            return (v) ? v.label : "Unknown"
        },
        formatGender(value) {
            if(!value) return "Not Selected"
            switch(value) {
                case 'male':
                case 'm':
                    return "Male"
                case 'female':
                case 'f':
                    return "Female"
                case 'other':
                case 'o':
                    return "Other/Do not specify"
            }
            return "Unknown"
        },
        formatBoolean(value) {
            if(value==null) return "Not Selected"
            return (value) ? "Yes" : "No"
        },
        formatAddress(value) {
            if(!value) return "No Value Entered"
            let a = ""

            if(value.address_line_1) a += value.address_line_1
            if(value.address_line_2) a += ", "  + value.address_line_2
            if(value.city) a += ", "  + value.city
            if(value.pincode) a += ", "  + value.pincode
            if(value.state) a += ". "  + this.formatState(value.state)
            if(value.district) a += " - "  + this.formatDistrict(value.state, value.district)

            a = a.trim()

            if(a.length == 0) return "No Value Entered"
            return a
        },
        formatNationality(value) {
            if(!value) return "Not Selected"
            let v = Countries.find( v => v.value == value)
            
            return (v) ? v.label : "Unknown"
        },
        formatState(value) {
            if(!value) return "Not Selected"
            let v = stateAndDistrictOptions.find( v => v.value == value)
            
            return (v) ? v.label : "Unknown"
        },
        formatDistrict(state, value) {
            if(!state) return "State Not Selected"
            if(!value) return "Value Not Selected"
            
            let val = null

            stateAndDistrictOptions.forEach( v => {
                if(v.value == state) {
                    v.districts.forEach(v2 => {
                        if(v2.value == value)
                            val = v2
                            return
                    })
                }
                if(val)
                    return
            })
            
            return (val) ? val.label : "Unknown"
        },
        formatCurrency(value, disable_decimal=false) {
            let prefix = ""
            if(value < 0) prefix = "-"
            if(!value) return "₹ 0"
            return prefix + "₹ " + this.formatNumber(value, disable_decimal)
        },
        formatNumber(value, disable_decimal=false) {
            if(!value || (value < 0.0001 && value > 0)) return "0"

            value = value.toString()
            value = value.replace("-", "")

            let values = value.split(".")
            let b_dec = values[0]
            
            let has_dot = value.includes(".")
            let a_dec = null
            if(!disable_decimal) {
                if(values.length > 1)
                    a_dec = values.slice(1, values.length).join("")
            }            
            
            let parts = []
            if(b_dec.length >= 4) {
                let rem = b_dec.slice(0, -3)
                parts = rem.match(/\d{1,2}(?=(\d{2})*$)/g)
                parts.push(
                    b_dec.slice(-3, b_dec.length)
                )
            } else {
                parts = [b_dec, ]
            }
            
            if(disable_decimal)
                return (parts.length?parts.join(","):"0")
            
            if(this.kwargs && this.kwargs.fixed_decimal) {
                if(a_dec)
                    a_dec = a_dec.slice(0, 2)
                if(a_dec)
                    while(a_dec.length < this.kwargs.fixed_decimal)
                        a_dec += "0"
            }
            
            return (parts.length?parts.join(","):"0") + ((a_dec || has_dot)?".":"") +  (a_dec?a_dec:"")
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    }
}
</script>
