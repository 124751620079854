export default {
    updateActiveOrganisation({commit, state, dispatch}, organisation) {
        commit('UPDATE_ACTIVE_ORGANISATION', organisation)

        if (state.entity && state.organisation != state.entity.organisation) {
          dispatch('updateActiveEntity', null)
        }
    },
    updateOrganisationUser({commit}, user) {
      commit('UPDATE_ORGANISATION_USER', user)
    },
    updateActiveEntity({commit}, entity) {
        commit('UPDATE_ACTIVE_ENTITY', entity)
    },
}
