export default {
    role(state) {
      return state.organisation_user ? state.organisation_user.role : null
    },
    lastOrganisation() {
      let organisation = localStorage.getItem('last_organisation')
      if (!organisation) return null
  
      organisation = JSON.parse(organisation)
      let user = localStorage.getItem('last_organisation_user')
      if (!user) return null
  
      organisation.user = JSON.parse(user)
  
      return organisation
    },
  }
  