<template>
    <div :class="container_class">
        <template v-if="!disabled">
            <label :class="label_class" v-if="label">{{ label }}</label>
            <v-select
                :class="input_class"
                @search="do_search"
                @input="updateModel"
                :options="value_options"
                v-model="value"
                :filterable="false"
                :multiple="multiple"
                :placeholder="placeholder"
                :label="label_keys?label_keys[0]:'label'"
            >
                <template slot="selected-option" scope="option">
                    <template v-for="(lk, i) in label_keys">
                        <span v-if="option[lk]" :key="i">{{ option[lk] }}</span>
                    </template>
                    <template v-if="show_id_in_label && option.id">
                        <small class="ml-2">(ID: {{ option.id }})</small>
                    </template>
                </template>
                <template v-slot:option="option">
                    <div class="block">
                      <template v-for="(lk, i) in label_keys">
                          <span v-if="option[lk]" :key="i">{{ option[lk] }}</span>
                      </template>
                    </div>
                    <template v-if="show_id_in_label && option.id">
                      <small class="block">
                          ID: {{ option.id }}
                      </small>
                    </template>
                </template>
            </v-select>
            <div v-if="warning" class="text-danger mt-2 mb-3">
                <small>{{ warning_text }}</small>
            </div>
        </template>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import { Request } from "../../../utils/requests"

export default {
    props: {
        container_class: {
            required: false,
            default: 'vx-col w-full'
        },
        disabled: {
            required: false,
            default: false
        },
        label: {
            required: false,
            default: null
        },
        label_class: {
            required: false,
            default: 'mb-1 vs-input--label'
        },
        input_class: {
            required: false,
            default: 'mt-0 w-full bg-white'
        },
        model: {
            required: true
        },
        placeholder: {
            required: false,
            default: 'Type to Search..'
        },
        warning: {
            required: false,
            default: false
        },
        warning_text: {
            required: false,
            default: null
        },
        multiple: {
            default: false
        },
        endpoint: {
            required: true
        },
        list_length: {
            default: 20
        },
        label_keys: {
            required: true,
            default: () => ['id', ]
        },
        show_id_in_label: {
            type: Boolean,
            default: false
        },
        load_initial: {
            default: false
        },
        use_id_as_value: {
            default: false
        },
        data_key: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            value: this.model,
            value_options: []
        }
    },
    mounted() {
        this.user = this.model
        if(this.load_initial)
            this.do_search(null, ()=>{}, true)
    },
    components: {
        vSelect,
    },
    watch: {
        model(newV, oldV) {
            if(newV == oldV) return
            if(this.use_id_as_value)
                this.value = this.value_options.find(v => v.id == newV) || newV
            else
                this.value = newV
        },
        endpoint() {
            this.do_search(null, ()=>{}, true)
        }
    },
    methods: {
        updateModel() {
            if(this.value && this.use_id_as_value)
                this.$emit("update:model", this.value.id)
            else
                this.$emit("update:model", this.value)
        },
        do_search(search, loading, skip_search=false){
            if(!skip_search && search.length < 1)
                return

            let data = {
                limit: this.list_length
            }
            if(!skip_search)
                data['search'] = search

            loading(true)
            let url = this.endpoint

            Request(this, 'GET', url, data).then(
                (res) => {
                    if(!this.data_key)
                        this.value_options = res.results
                    else
                        this.value_options = res.results[this.data_key]

                    loading(false)
                }, (err) => {
                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: 'An Error Occured. Please Try Again',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                    this.value_options = []
                    loading(false)
                }
            )

        },
    }
}
</script>
