===================
Letstream Input Aadhar Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - aadhar (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
    <vs-input 
        :value="formatted_value"
        :label="label"
        :success="is_success"
        :danger="is_invalid"
        :class="input_class"
        :success-text="successText"
        :danger-text="dangerText"
        :placeholder="placeholder"
        :warning-text="warning_text"
        :warning="warning"
        @input="valueChanged($event)"
        :disabled="disabled"
    />
</template>

<script>
export default {
    props: {
        field : {
            default: null,
        },
        label : {
            type: String,
            default: '',
        },
        successText : {
            type: String,
            default: null,
        },
        dangerText : {
            type: String,
            default: null,
        },
        placeholder : {
            type: String,
            default: '',
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        },
        input_class: {
            default: null
        },
        disabled: {
            default: false
        }
    },
    data() {
        return {
            reg: /^\d+(\.\d{1,2})?$/g,
            is_success : null,
            is_invalid : null,
            value: null,
        }
    }, 
    computed: {
        formatted_value () {
            return this.format_value(this.field)
        }
    },
    mounted() {
        this.valueChanged(this.field)
    },
    watch: {
        field: function(value, oldV) {
            if(value == oldV) return
            this.valueChanged(value)
        },
    },
    methods: {
        valueChanged(value) {
            if(!value || (value && value.toString().trim().length == 0)){ 
                this.is_success = null
                this.is_invalid = null
                this.value = null
                return
            }

            let sanitized_value = this.sanitize(value)
            let is_valid = this.reg.test(sanitized_value)
            if(is_valid){
                this.is_success = true
                this.is_invalid = null
                this.$emit('success', this.successText)
                this.$emit('update:field', sanitized_value)
                this.$emit('change', sanitized_value)
            } else {
                this.is_success = null
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:field', sanitized_value)
                this.$emit('change', sanitized_value)
            }
            this.value = value
        },
        sanitize(value) {
            let v = value.toString().replace(/[^\d\.]/g, "")
            if (parseFloat(v) > 100)
                v = "100"
            else if(parseFloat(v) < 0)
                v = "0"
            return v
        },
        format_value(value) {
            if(!value) return null
            value = value.toString()
            let parts = value.match(/^(?<start>\d+)(\.(?<end>\d{2}))?$/)
            if(!parts || (parts && !parts.groups)) return value + " %"
            
            return parts.groups.start + (parts.groups.end?("." + parts.groups.end):"") + " %"
        }
    }
}
</script>