===================
Letstream Input Cin Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - cin (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
    <vs-input 
        v-model="value"
        :label="label"
        :success="is_success"
        :danger="is_invalid"
        class="w-full"
        :success-text="successText"
        :danger-text="dangerText"
        :placeholder="placeholder"
        :disabled="disabled"
    />
</template>

<script>
export default {
    props: {
        cin : {
            default: null,
        },
        label : {
            type: String,
            default: 'Enter Company Identification Number (CIN)',
        },
        successText : {
            type: String,
            default: 'CIN is Valid',
        },
        dangerText : {
            type: String,
            default: 'CIN is Invalid',
        },
        placeholder : {
            type: String,
            default: 'Enter CIN',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            reg: /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
            is_success : false,
            is_invalid : false,
            value: this.cin,
        }
    },
    mounted() {
        this.value = this.cin
    },
    watch: {
        cin: function(newV, oldV) {
            if(newV == oldV) return
            this.value = newV
        },
        value: function (value, oldV) {
            if(value == oldV) return

            if(!value || (value && value.toString().trim().length == 0)){
                this.is_success = false
                this.is_invalid = false
                return
            }

            if(this.reg.test(value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:cin', value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:cin', value)
            }
        }
    }
}
</script>