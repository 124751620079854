import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'
import authService from '../auth/authService'
import store from '../store/store'

Vue.use(AclInstaller)

let initialRole = store.state.auth.userRole
// if(localStorage.getItem('userRole')) initialRole = localStorage.getItem('userRole')

export default new AclCreate({
  initial: initialRole,
  notfound: {
    path: '/errors/not-authorized',
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule('admin').generate(),
    employee: new AclRule('employee').or('admin').generate(),
    reseller: new AclRule('reseller').or('admin').generate(),
    subscriber: new AclRule('subscriber').or('admin').or('reseller').or('employee').generate(),
    subscriberonly: new AclRule('subscriber').generate(),
    public: new AclRule('public').or('subscriber').or('admin').or('reseller').or('employee').generate(),
    publicOnly: new AclRule('public').generate()
  }
})
