===================
Letstream Input Llpin Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - llpin (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
        <vs-input 
            :value="formatted_value"
            :label="label"
            :success="is_success"
            :danger="is_invalid"
            class="w-full"
            :success-text="successText"
            :danger-text="dangerText"
            :placeholder="placeholder"
            @input="valueChanged($event)"
            :disabled="disabled"
        />
</template>

<script>
export default {
    props: {
        llpin : {
            default: null,
        },
        label : {
            type: String,
            default: 'Enter LLP Identification Number',
        },
        successText : {
            default: 'Valid LLPIN',
        },
        dangerText : {
            default: 'Invalid LLPIN',
        },
        placeholder : {
            default: 'Enter your 7 digit LLPIN',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            reg: /^[a-zA-z]{3}[0-9]{4}$/,
            is_success : null,
            is_invalid : null,
            value: null,
        }
    },
    computed: {
        formatted_value () {
            return this.format_value(this.value)
        }
    },
    mounted() {
        this.valueChanged(this.llpin)
    },
    watch: {
        llpin (newV, oldV) {
            if(newV == oldV) return
            this.valueChanged(newV)
        },
    },
    methods: {
        valueChanged(value) {
            if(!value || (value && value.toString().trim().length == 0)){ 
                this.is_success = false
                this.is_invalid = false
                this.value = null
                return
            }

            let sanitized_value = this.sanitize(value)
            
            if(this.reg.test(sanitized_value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:llpin', sanitized_value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:llpin', sanitized_value)
            }

            this.value = value
        },
        sanitize(value) {
            return value.toString().replace(/[^\dA-Za-x]/g, "")
        },
        format_value(value) {
            if(!value) return value

            let parts = value.match(/^(?<start>[A-Za-z]{3})(?<end>[0-9]{4})$/)
            if(!parts || (parts && !parts.groups)) return value

            return parts.groups.start + "-" + parts.groups.end
        }
    }
}
</script>