<!-- =========================================================================================
    Description :  Letstream Nation Select Menu Component
    Author: @letstream
========================================================================================== -->

<template>
    <InputSelect 
        :model.sync="value_state" 
        :label="label_state"
        :placeholder="placeholder"
        :options="options"
        :container_class="class_state"
        :warning-text="warning_text"
        :warning="warning"
    />
</template>
<script>

import StateOptions from "./districts.json"

let state_list = [] 
let state_district_options = StateOptions
state_district_options.forEach(v => {
    state_list.push({
        'label': v['label'],
        'value': v['value'],
    })
})
export default {
    data() {
        return {
            value_state: this.state,
            options: state_list
        }
    },
    props: {
        container_class: {
            default: "w-full"
        },
        class_state : {
            default : 'w-full select-large', 
        },
        label_state: {
            default : "State",
        },
        placeholder: {
            default : null,
        },
        state: {
            default : null,
        },
        warning_state: {
            default: false
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.value_state = this.state
    },
    watch: {
        value_state: function(value, oldV){
            if(value == oldV) return
            this.$emit('update:state', value)
        }
    }
}
</script>
