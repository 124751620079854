/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const getters = {

	// COMPONENT
		// vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  userInfo(state, getters){
    return JSON.parse(localStorage.getItem('userInfo'))
  },
  getActiveStore(state, getters){
    return JSON.parse(localStorage.getItem('active_store'))
  },
  getToken(state, getters) {
    return JSON.parse(localStorage.getItem('token'))
  },
  fcmProgress(state, getters) {
    return localStorage.getItem('fcm_progress')
  }
}

export default getters