<template>
    <div :class="container_class">
        <label class="vs-input--label" v-if="label">{{ label }}</label>
        <v-select
            v-model="value"
            :options="options"
            :placeholder="placeholder"
            :closeOnSelect="close_on_select"
            :multiple="multiple_true"
            :class="input_class"
            :disabled="disabled"
            :label="this.label_key"
        />
        <small class="text-danger d-block" v-if="warning">{{ warning_text }}</small>
    </div>
</template>
<script>
import vSelect from 'vue-select'

export default {
    props: {
        label: {
            default: null
        },
        options: {
            default: ()=>[]
        },
        warning: {
            default: false
        },
        close_on_select: {
            default: true
        },
        multiple_true: {
            default: false
        },
        warning_text: {
            default: null
        },
        input_class: {
            default: 'bg-white'
        },
        placeholder: {
            default: null
        },
        model: {
            default: null
        },
        disabled: {
            default: false
        },
        container_class: {
            default: 'w-full'
        },
        clear_if_not_found: {
            default: false
        },
        label_key: {
            default: 'label'
        },
        value_key: {
            default: 'value'
        },
    },
    components: {
        'v-select': vSelect
    },
    data() {
        return {
            value: null
        }
    },
    mounted() {
        this.setActualValue(this.model)
    },
    watch: {
        model(value) {
            this.setActualValue(value)
        },
        value(newV, oldV){
            if(newV == oldV) return
            if (this.multiple_true){
                if (Array.isArray(newV) && Array.isArray(oldV)) {
                    if (newV.length == oldV.length && newV.every((v,i) => v===oldV[i])) return
                }
            }
            let value_list = []
            let value_key = this.value_key
            if (Array.isArray(newV)){
                Object.keys(newV).forEach(function(key) {
                    value_list.push(newV[key][value_key])
                });
                this.$emit('update:model', value_list)
                this.$emit('change', value_list)
            }else{
                this.$emit('update:model', newV?newV[value_key]:newV)
            this.$emit('change', newV?newV['value']:newV)}
        },
        options(newV, oldV) {
            this.setActualValue()
        }
    },
    methods: {
        setActualValue(value){
            if(value == undefined) value = this.model
            let is_found = false
            let temp_value = []
            this.options.forEach( (v,i) => {
                if (this.multiple_true){
                    if (value && Array.isArray(value)){
                        value.forEach(val => {
                            if(v[this.value_key] == val) {
                                temp_value.push(v)
                                is_found = true
                            }
                        })
                    }
                }else{
                    if(v[this.value_key] == value) {
                        this.value = v
                        is_found = true
                        return
                    }}
            })
            if (this.multiple_true){
                this.value = temp_value
            }

            if(!is_found && this.clear_if_not_found){
                this.value = null
            }
        }
    }
}
</script>
