===================
Letstream Input Aadhar Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - aadhar (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
    <vs-input 
        :value="formatted_value"
        :label="label"
        :success="is_success"
        :danger="is_invalid"
        :class="input_class"
        :success-text="successText"
        :danger-text="dangerText"
        :placeholder="placeholder"
        :warning-text="warning_text"
        :warning="warning"
        @input="valueChanged($event)"
        :disabled="disabled"
        @change="addZeroZero"
    />
</template>

<script>
export default {
    props: {
        field : {
            default: null,
        },
        label : {
            type: String,
            default: '',
        },
        successText : {
            type: String,
            default: '',
        },
        dangerText : {
            type: String,
            default: '',
        },
        placeholder : {
            type: String,
            default: '',
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        },
        input_class: {
            default: null
        },
        disable_symbol: {
            default: false
        },
        disable_decimal: {
            default: false
        },
        disabled: {
            default: false
        }
    },
    data() {
        return {
            reg: (this.disable_decimal)?(/[\d]/g):(/[\d\.]/g),
            is_success : null,
            is_invalid : null,
            value: null,
        }
    }, 
    computed: {
        formatted_value () {
            return this.format_value(this.field)
        }
    },
    mounted() {
        this.valueChanged(this.field)
        this.reg = (this.disable_decimal)?(/[\d]/g):(/[\d\.]/g)
    },
    watch: {
        field: function(value, oldV) {
            if(value == oldV) return
            this.valueChanged(value)
        },
    },
    methods: {
        valueChanged(value) {
            if(!value || (value && value.toString().trim().length == 0)){ 
                this.is_success = false
                this.is_invalid = false
                this.value = null
                return
            }
            let sanitized_value = this.sanitize(value)
            
            if(this.reg.test(sanitized_value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:field', sanitized_value)
                this.$emit('change', sanitized_value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:field', sanitized_value)
                this.$emit('change', sanitized_value)
            }

            this.value = value
        },
        sanitize(value) {
            return value.toString().replace((this.disable_decimal)?/[\D]/g:/[^\d\.]/g, "")
        },
        format_value(value) {
            let prefix = (this.disable_symbol)?"":"₹ "
            if(!value) return prefix

            value = value.toString()

            let values = value.split(".")
            let b_dec = values[0]
            
            let has_dot = value.includes(".")
            let a_dec = null
            if(!this.disable_decimal) {
                if(values.length > 1)
                    a_dec = values.slice(1, values.length).join("")
            }            
            
            let parts = []
            if(b_dec.length >= 4) {
                let rem = b_dec.slice(0, -3)
                parts = rem.match(/\d{1,2}(?=(\d{2})*$)/g)
                parts.push(
                    b_dec.slice(-3, b_dec.length)
                )
            } else {
                parts = [b_dec, ]
            }
            
            if(this.disable_decimal)
                return prefix + (parts.length?parts.join(","):"0")
            
            return prefix + (parts.length?parts.join(","):"0") + ((a_dec || has_dot)?".":"") +  (a_dec?a_dec:"")
        },
        addZeroZero(){
            if(this.disable_decimal) return 
            let values = this.value.split(".")
            if(values.length < 2){
                this.valueChanged(this.value + ((this.value.toString().includes("."))?"":".") + "00")
            }

        }
    }
}
</script>