<template>
	<div id="app">
    <offline @detected-condition="handleConnectivityChange"></offline>
    <OnboardingScreenProfile v-if="false" />
    <vs-row class="w-full update-available bg-warning" v-if="showUpgradeUI.active" vs-justify="center" vs-align="center">
      <vs-col class="text-center">
        We've finetuned Legully in the background. Please reload to activate new features. <vs-button type="filled" color="primary" @click="accept" size="small">Reload</vs-button>
      </vs-col>
    </vs-row>
    <router-view></router-view>
	</div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import offline from 'v-offline';

import OnboardingScreenProfile from "./views/user/OnboardingScreenProfile";

export default {
  components: {
    offline,
    OnboardingScreenProfile
  },
  data: () => {
    return  {
      showUpgradeUI: {
        active: false,
        data: null,
      }
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    },
    '$store.state.auth.userRole'(val) {
      this.$acl.change(val)
    }
  },
  created() {
    window.addEventListener("swRegistered", (e) => {
      console.log(e)
      this.$store.dispatch('updateServiceWorker', e.detail.registration)
    });
    window.addEventListener("swUpdateAvailable", (e) => {
      this.showUpgradeUI.active = true
      this.showUpgradeUI.data = e.detail.worker;
    });
    window.addEventListener("firebaseRegistered", (e) => {
      this.$store.dispatch('firebaseRegistered', this)
    })
  },
  methods: {
    handleConnectivityChange(status){
      this.$store.dispatch("updateConnectivityStatus", status)
    },
    accept() {
      this.showUpgradeUI.data.postMessage({type: 'SKIP_WAITING'})
    },
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark');
      }
    },
    handleWindowResize(event) {
      this.$store.dispatch('updateWindowWidth', event.currentTarget.innerWidth);
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize);
    });
    this.$store.dispatch('updateWindowWidth', window.innerWidth);
    this.$store.dispatch('loadActiveEntity');

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
}

</script>

<style lang="scss">
.update-available {
  position: fixed;
  bottom: 0;
  height: 60px;
  color: #fff;
  font-weight: 700;
  z-index: 999999;
}
</style>
