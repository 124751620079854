/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
import VxCard  from './components/vx-card/VxCard.vue'
import VxList  from './components/vx-list/VxList.vue'
import VxBreadcrumb  from './layouts/components/VxBreadcrumb.vue'
import FeatherIcon  from './components/FeatherIcon.vue'
import VxInputGroup  from './components/vx-input-group/VxInputGroup.vue'
import TableData from './components/letstream/table-data/TableData.vue'
import GoogleAutoComplete from  './components/letstream/google-maps-autocomplete/GoogleAutoComplete.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

//components imported by me start

import TextFieldPincode from './components/letstream/form-components/TextFieldPincode/TextFieldPincode.vue'
import TextFieldPhoneNumber from './components/letstream/form-components/TextFieldPhoneNumber/TextFieldPhoneNumber.vue'
import TextFieldEmail from './components/letstream/form-components/TextFieldEmail/TextFieldEmail.vue'
import FileSelection from './components/letstream/form-components/FileSelection/FileSelection.vue'
import NationSelectMenu from './components/letstream/form-components/NationSelectMenu/NationSelectMenu.vue'
import InputNationality from './components/letstream/ls-input-nationality/component.vue'
import StateAndDistrictSelectMenu from './components/letstream/form-components/StateAndDistrictSelectMenu/StateAndDistrictSelectMenu.vue'


//components imported by me ends
import InputCin from './components/letstream/ls-input-cin/InputCin'
import SelectGender from './components/letstream/ls-select-gender/SelectGender'
import InputPan from './components/letstream/ls-input-pan/InputPan'
import InputGst from './components/letstream/ls-input-gst/InputGst'
import InputLlpin from './components/letstream/ls-input-llpin/InputLlpin'
import SelectDate from './components/letstream/ls-select-date/SelectDate'
import InputAadhar from './components/letstream/ls-input-aadhar/InputAadhar'
import InputBankDetails from './components/letstream/ls-input-bank-details/InputBankDetails'
import InputAddress from './components/letstream/ls-input-address/InputAddress'
import InputSelect from './components/letstream/ls-select/InputSelect'
import InputCurrency from './components/letstream/ls-input-currency/InputCurrency'
import InputPercentage from './components/letstream/ls-input-percentage/InputPercentage'
import InputYear from './components/letstream/ls-input-year/InputYear'
import InputBoolean from './components/letstream/ls-select-boolean/InputBoolean'
import SelectDistrict from './components/letstream/ls-select-district/SelectDistrict'
import SelectState from './components/letstream/ls-select-state/SelectState'
import SelectCustom from './components/letstream/ls-select-custom/SelectCustom'
import FormatText from './components/letstream/ls-text-formatter/FormatText'
import HelpdeskAlert from './components/letstream/ls-helpdesk-alert/HelpdeskAlert'
import SelectForeign from './components/letstream/ls-select-foreign/SelectForeign'

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component('InputCin', InputCin)
Vue.component('SelectGender', SelectGender)
Vue.component('InputPan', InputPan)
Vue.component('InputGst', InputGst)
Vue.component('InputLlpin', InputLlpin)
Vue.component('SelectDate', SelectDate)
Vue.component('InputAadhar', InputAadhar)
Vue.component('InputBankDetails', InputBankDetails)
Vue.component('InputAddress', InputAddress)
Vue.component('InputNationality', InputNationality)
Vue.component('InputSelect', InputSelect)
Vue.component('InputCurrency', InputCurrency)
Vue.component('InputPercentage', InputPercentage)
Vue.component('InputYear', InputYear)
Vue.component('InputBoolean', InputBoolean)
Vue.component('SelectDistrict', SelectDistrict)
Vue.component('SelectState', SelectState)
Vue.component('SelectCustom', SelectCustom)
Vue.component('SelectForeign', SelectForeign)
Vue.component('FormatText', FormatText)


//added by me starts
Vue.component('TextFieldPincode', TextFieldPincode)
Vue.component('TextFieldPhoneNumber', TextFieldPhoneNumber)
Vue.component('TextFieldEmail', TextFieldEmail)
Vue.component('FileSelection', FileSelection)
Vue.component('NationSelectMenu', NationSelectMenu)
Vue.component('StateAndDistrictSelectMenu', StateAndDistrictSelectMenu)
Vue.component('HelpdeskAlert', HelpdeskAlert)
//added by me ends

Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component(TableData.name, TableData)
Vue.component(GoogleAutoComplete.name, GoogleAutoComplete)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// v-select component
import vSelect from 'vue-select'
import { Request } from './utils/requests'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    }),
  },
});

Vue.component(vSelect)

var cloneDeep = require('lodash.clonedeep');
var sprintf = require('sprintf-js').sprintf

Vue.mixin({
  methods: {
    deepclone: (object) => cloneDeep(object),
    sprintf: (...args) => sprintf(...args),
    $api: Request
  }
})
