===================
Letstream Input Aadhar Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - aadhar (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
    <vs-input 
        :value="formatted_value"
        :label="label"
        :success="is_success"
        :danger="is_invalid"
        class="w-full"
        :success-text="successText"
        :danger-text="dangerText"
        :placeholder="placeHolder"
        :warning_text="warning_text"
        :warning="warning"
        :disabled="disabled"
        @input="valueChanged($event)"
    />
</template>

<script>
export default {
    props: {
        aadhar : {
            type: String,
            default: '',
        },
        label : {
            type: String,
            default: '',
        },
        successText : {
            type: String,
            default: '',
        },
        dangerText : {
            type: String,
            default: '',
        },
        placeHolder : {
            type: String,
            default: '',
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            reg: /^\d{4}\d{4}\d{4}$/,
            is_success : null,
            is_invalid : null,
            value: null,
        }
    },
    computed: {
        formatted_value () {
            return this.format_value(this.value)
        }
    },
    mounted() {
        this.valueChanged(this.aadhar)
    },
    watch: {
        aadhar(newV, oldV) {
            if(newV == oldV) return
            this.valueChanged(newV)
        },
    },
    methods: {
        valueChanged(value) {
            if(!value || (value && value.toString().trim().length == 0)){ 
                this.is_success = false
                this.is_invalid = false
                this.value = null
                return
            }

            let sanitized_value = this.sanitize(value)
            
            if(this.reg.test(sanitized_value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:aadhar', sanitized_value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:aadhar', sanitized_value)
            }

            this.value = value
        },
        sanitize(value) {
            return value.toString().replace(/\D/g, "")
        },
        format_value(value) {
            if(!value) return

            let parts = value.match(/.{1,4}/g)

            return parts.join("-")
        }
    }
}
</script>