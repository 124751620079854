import { api } from "../../utils/requests";
import apiendpoints from "../../../apiendpoints";

export default {
    loginAttempt({ dispatch }, payload) {},
    updateRole({commit}, payload) {
        // console.log("Updating Role")
        commit('UPDATE_AUTHENTICATED_ROLE', payload.role);
        if(payload.instance)
            payload.instance.$acl.change(payload.role)
    },
    updateUserToken ({ commit }, token) {
        commit('UPDATE_USER_TOKEN', token)
    },
    updateAuthenticatedUser({ commit }, payload) {
        commit('UPDATE_AUTHENTICATED_USER', payload)
        if (window.FreshworksWidget) {
          FreshworksWidget('prefill', 'ticketForm', {
              source: 'Chat',
              name: payload.first_name + (payload.last_name ? ` ${payload.last_name}` : ''),
              email: payload.email
            }
          );
        }
    },
    logout({commit, dispatch}){
        return new Promise((resolve, reject) => {
            dispatch('updateRole', {role: 'public'})
            dispatch('updateAuthenticatedUser', null)
            commit('LOGOUT_USER')

            let t = api.logout({url: apiendpoints.logout})

            if (t) {
                t.then(
                    () => {
                        resolve()
                    },
                    err => {
                        reject(err)
                    }
                )
            } else {
                resolve()
            }
        })
    }
}
