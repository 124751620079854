<template>
    <vs-prompt 
        :active.sync="is_active"
        :buttons-hidden="true"
        title="Let's get to know you better"
        color="primary"
    >
        <vs-row class="mb-3">
            <vs-col>
                <p class="text-center">Welcome to Legully! We just need a few things to make your experience at Legully even better!</p>
            </vs-col>
        </vs-row>
        <vs-row>
            <vs-col>
                <vs-input
                    v-model="profile.mobile_number"
                    label="Mobile Number"
                    placeholder="Enter your 10 digit Mobile Number"
                    :danger="api_errors.mobile_number"
                    :danger-text="api_errors.mobile_number ? api_errors.mobile_number[0] : null"
                    class="w-full mb-3"
                />
            </vs-col>
            <vs-col>
                <label class="w-full vs-input--label">Who are you?</label>
                <br/>
                <vs-radio
                    v-model="profile.user_type"
                    vs-name="user_type"
                    vs-value="pr"    
                    class=" mt-2 mb-3 mr-3"
                >Professional</vs-radio>
                <vs-radio
                    v-model="profile.user_type"
                    vs-name="user_type"
                    vs-value="bu" 
                    class="ml-3 mt-2 mb-3"   
                >Business (SME)</vs-radio>
                <vs-radio
                    v-model="profile.user_type"
                    vs-name="user_type"
                    vs-value="ot" 
                    class="ml-3 mt-2 mb-3"   
                >Other</vs-radio>
            </vs-col>
            <vs-col>
                <SelectState
                    :model.sync="profile.address_state"
                    class="w-full mb-3"
                    placeholder="Select State"
                    label_state="Where are you from?"
                />
            </vs-col>
            <vs-col>
                <InputSelect
                    :model.sync="profile.employee_base"
                    :options="employee_base_options"
                    class="w-full mb-3"
                    placeholder="Select an option"
                    label="How big is your Team?"
                />
            </vs-col>
            <vs-col>
                <InputSelect
                    :model.sync="profile.referral_source"
                    :options="referral_source_options"
                    class="w-full mb-3"
                    placeholder="Select an option"
                    label="How did you hear about us?"
                />
            </vs-col>
        </vs-row>
        <vs-row>
            <vs-col class="text-center">
                <vs-button
                    color="primary"
                    @click="save"
                    :disabled="submit_disabled"
                >Save</vs-button>
            </vs-col>
        </vs-row>
    </vs-prompt>
</template>

<script>
import { Request } from '../../utils/requests'
export default {
    data() {
        return {
            is_active: false,
            profile: {},
            api_errors: {},
            submit_disabled: false,

            employee_base_options: [
                {label: "<5", value: "lt5"},
                {label: "5-10", value: "5-10"},
                {label: "20-100", value: "20-100"},
                {label: ">100", value: "gt100"},
            ],
            referral_source_options: [
                {label: "Google/Bing Search", value: "search"},
                {label: "Article", value: "article"},
                {label: "Facebook Advt", value: "fb_ad"},
                {label: "YouTube Advt", value: "youtube_ad"},
                {label: "Webinar/Seminar", value: "web_sem"},
                {label: "Friend", value: "friend"},
                {label: "Other", value: "other"},
            ]
        }
    },
    mounted() {
        this.is_active = this.$store.state.auth.user != null && this.$store.state.auth.user.mobile_number == null
        this.profile['mobile_number'] = this.$store.state.auth.user.mobile_number
        this.profile = {...this.profile, ...this.$store.state.auth.user.profile}
    },
    watch: {
        '$store.state.auth.user'(newV, oldV) {
            this.is_active = this.$store.state.auth.user != null && this.$store.state.auth.user.mobile_number == null
            this.profile['mobile_number'] = this.$store.state.auth.mobile_number
            this.profile = {...this.profile, ...this.$store.state.auth.profile}
        }
    },
    methods: {
        save() {
            this.submit_disabled = true
            Request(this, 'POST', this.$store.state.apiendpoints.user_profile, null, this.profile).then(
                (res) => {
                    window.location.reload()
                },
                (err) => {
                    if(err.error) {
                        this.api_errors = err.error
                        this.$vs.notify({
                            time: 4000,
                            title: 'Error',
                            text: 'Please fix the errors and try again.',
                            color: 'danger'
                        })
                        this.submit_disabled = false
                    } else {
                        this.$vs.notify({
                            time: 4000,
                            title: 'Error',
                            text: 'Unknown Error Occurred. Please try again.',
                            color: 'danger'
                        })
                        this.submit_disabled = false
                    }
                }
            )
        }
    }
}
</script>