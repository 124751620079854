<template>
  <div>
    <h3 v-if="loading" class="text-center">
      Loading..Please wait..
    </h3>
    <vs-table v-else search maxItems="20" :data="final_rows" :sst="true" @search="handleSearch" @change-page="handleChangePage">

      <template slot="thead">
        <template v-for="(field, index) in final_fields">
          <vs-th :key="index" :class="field.class">{{ (field.label != "...") ? field.label : '' }}</vs-th>
        </template>
        <vs-th class="w-1"></vs-th>
      </template>

      <template>
        <template v-for="(row, indextr) in final_rows">
          <vs-tr class="bg-dark text-white" v-bind="row.attrs" v-bind:key="indextr" v-if="row.header">
            <vs-td :data="row" colspan="fields.length">{{ row.header }}</vs-td>
          </vs-tr>

          <template v-for="(group, indexg) in row.groups">
            <vs-tr :data="group" :key="'row-' + indextr + '-' + indexg" :id="'row-' + row.id + '-' + indexg" v-bind="row.attrs" :data-id="row.id">
              <vs-td :data="d" v-for="(d, indexdd) in group" :key="'cell-'+ indexg + '-' + indexdd" :style="(d.meta)?(d.meta.style):''"
                :class="(d.meta)?d.meta.wrapper_class:''">
                <template v-if="d.type == 'text'">
                  <div :class="(d.meta)?d.meta.css_class:''">{{ d.text }}</div>
                  <div class="small text-muted">{{ d.subtext }}</div>
                </template>

                <template v-if="d.type == 'text-secondary'">
                  <div class="small text-muted" :class="(d.meta)?d.meta.css_class:''">{{ d.text }}</div>
                </template>

                <template v-if="d.type == 'avatar'">
                  <vs-avatar size="small" src="(d.meta)?d.meta.url:''"></vs-avatar>
                </template>
              </vs-td>

              <vs-td 
                v-if="indexg == 0" 
                class="action-buttons text-right align-middle" 
                rowspan="row.groups.length"
              >
                <template v-for="(action, indexa) in row.actions" >
                  <template v-if = "action.params">
                    <router-link
                      :to="{name: action.url, params: action.params, props: action.props}" 
                      v-bind:key="'router-'+indexa"
                      :id="row.id"
                      :target="(action.open_in_new)?'_blank':'_self'"
                      :data-toggle="action.data_toggle" 
                    >
                      <vs-button size="small" :color="action.btn_class">{{ action.label }}</vs-button>
                    </router-link>
                  </template>
                  <template v-else>
                    <a
                      v-bind:key="'normal-' + indexa"
                      :href="action.url" 
                      :id="row.id"
                      :target="(action.open_in_new)?'_blank':'_self'"
                      :data-toggle="action.data_toggle" 
                    >
                      <vs-button size="small" :color="action.btn_class">{{ action.label }}</vs-button>
                    </a>
                  </template>
                </template>
              </vs-td>

            </vs-tr>
          </template>
        </template>
      </template>
    </vs-table>
    <div 
      class="vx-row w-full"
      v-if="hasPagination"
    >
      <div class="vx-col mb-3 w-full mt-3">
        <div class="con-vs-pagination vs-pagination-primary">
          <nav class="vs-pagination--nav">
            <button class="vs-pagination--buttons btn-prev-pagination vs-pagination--button-prev" :disabled="isFirstPage" @click.prevent="loadPreviousPage">
              <i class="vs-icon notranslate icon-scale material-icons null">chevron_left</i>
            </button>
            <ul class="vs-pagination--ul">
              <li 
                class="item-pagination vs-pagination--li"
                v-for="(value, index) in generatePageNumbers"
                :key="index"
                :class="{'is-current': value == pagination.page}"
                @click="loadPage(value)"
              >
                <span> {{ value }} </span><div class="effect"></div>
              </li>
            </ul>
            <button class="vs-pagination--buttons btn-next-pagination vs-pagination--button-next" :disabled="isLastPage" @click.prevent="loadNextPage">
              <i class="vs-icon notranslate icon-scale material-icons null">chevron_right</i>
            </button>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Request} from '../../../utils/requests.js';
  
  export default {
    name: 'ls-tabledata',
    props: {
      table_rows: Array,
      table_fields: Array,
      dataurl: String,
      dataurlpayload: Object,
      page: {
        required: false,
        type: Number,
        default: 1
      },
      pagesize: {
        required: false,
        type: Number,
        default: 20
      },
    },
    data() {
      return {
        search: null,
        pagination: {
          page: this.$route.query.page || this.page,
          pagesize: this.$route.query.pagesize || this.pagesize,
        },
        final_rows: [],
        final_fields: [],
        meta: {},
        loading: false,
      }
    },
    mounted(){
      if(this.dataurl){
        this.loadData()
      }
      else {
        this.final_rows = this.table_rows
        this.final_fields = this.table_fields
      }
    },
    watch: {
      table_rows: function(oldv, newv){
        this.final_rows = newv
      },
      table_fields: function(oldv, newv) {
        this.final_fields = newv
      },
      dataurlpayload: function(oldv, newv){
        this.loadData()
      },
      'pagination.page': function(oldV, newV) {
        this.loadData()
        if(this.pagination.page != this.$route.query.page) 
          this.$route.query.page = this.pagination.page
      },
      '$route.query.page': function(oldV, newV) {
        if(this.pagination.page != this.$route.query.page) {
          this.pagination.page = this.$route.query.page
        }
      },
      'pagination.pagesize': function(oldV, newV) {
        this.loadData()
      },
      loading(newV, oldV) {
        if(newV === true) this.$vs.loading()
        if(newV === false) this.$vs.loading.close()
      }
    },
    computed: {
      hasPagination() {
        return 'pagination' in this.meta
      },
      isFirstPage() {
        return this.pagination.page == 1
      },
      isLastPage() {
        return this.meta.pagination.next_url == null || this.meta.pagination.next_url == undefined
      },
      generatePageNumbers() {
        if(!this.hasPagination)
          return []
        let total_pages = parseInt((this.meta.pagination.total/this.meta.pagination.page_size))
        if(this.meta.pagination.total%this.meta.pagination.page_size != 0)
          total_pages += 1
        
        let pages = []
        if(this.pagination.page-2 > 1)
          pages.push('...')
        for(let x=this.pagination.page-2; x<this.pagination.page; x++){
          if(x < 1)
            continue
          pages.push(x)
        }
        for(let x=this.pagination.page; x<(this.pagination.page+2); x++){
          if(x >= total_pages)
            break
          pages.push(x)
        }
        if(this.pagination.page+2<total_pages)
          pages.push("...")
        pages.push(total_pages)
        return pages
      }
    },
    methods: {
      prepare_rows(){
        return this.final_rows
      },
      prepare_fields() {
        return this.final_fields
      },
      handleSearch(searching) {
        // console.log("Searching " + searching);
        this.search = searching;
        this.loadData();
      },
      handleChangePage(page) {
        // console.log("Requested Page " + page);
        this.page = page;
        this.loadData();
      },
      loadData(){
        this.loading = true

        let $this = this;
        let payload = $this.dataurlpayload;
        if(payload == null || payload == undefined)
          payload = {};
        
        payload['search'] = this.search;
        payload['page'] = this.pagination.page;
        payload['pagesize'] = this.pagination.pagesize;

        Request($this, 'GET', $this.dataurl, payload).then( (res) => {
              $this.final_rows = res.rows;
              $this.final_fields = res.fields;
              $this.meta = res.meta
              if(res.meta && res.meta.pagination) {
                this.pagination.page = res.meta.pagination.page
                this.pagination.pagesize = res.meta.pagination.page_size
              }
              this.loading = false
        }, (err) => {
            $this.$vs.notify({
                time: 2500,
                title: 'API Error',
                text: 'An Error Occurred. Please try again later.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
            this.loading = false
            // $this.$router.go('-1')
        })
      },
      loadPreviousPage(){
        if(!this.hasPagination) 
          return
        if(!this.meta.pagination.prev_url)
          return
        this.pagination.page -= 1
        this.$emit('update:page', this.pagination.page)
        
      },
      loadNextPage() {
        if(!this.hasPagination) 
          return
        if(!this.meta.pagination.next_url)
          return
        this.pagination.page += 1
        this.$emit('update:page', this.pagination.page)
      },
      loadPage(page) {
        if(!this.hasPagination) 
          return
        this.pagination.page = page
        this.$emit('update:page', this.pagination.page)
      }
    }
  }

</script>
