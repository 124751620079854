<template>
    <vs-alert active="true" class="my-3" icon-pack="feather" icon="icon-help-circle"> 
        Need Help with this?
        <a :href="url" target="_blank">
            <vs-button type="flat" color="primary" class="px-0">
                Checkout our knowledgebase
            </vs-button>
        </a>
    </vs-alert>
</template>

<script>
export default {
    props: {
        url: {
            required: true
        }
    }
}
</script>

<style lang="scss">
    .con-vs-alert {
        .vs-alert.con-icon {
            .vs-icon:first-child {
                padding-left: 16px;
                font-size: 1.3em;
            }
        }
    }
</style>