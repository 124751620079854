<!-- =========================================================================================
    Description :  Letstream Nation Select Menu Component
    Author: @letstream
========================================================================================== -->

<template>
    <InputSelect 
        :model.sync="value" 
        :input_class="'w-full select-large' + classAnother" 
        :class=classAnother
        :label=label
        :options="nationOptions"
    />        
</template>

<script>

const nationOptions = [
    {label: "Select Nation", value:"select-nation"},
    {label: "India", value:"india"},
    {label: "Russia", value:"russia"},
    {label: "Nepal", value:"nepal"},
    {label: "Sri Lanka", value:"srilanka"},
]

export default {
    data() {
        return {
            value: null,
            nationOptions: nationOptions,
        }
    },
    props: {
        classAnother : {
            default : '', 
        },
        label: {
            default : null,
        },
        model: {
            default : null,
        },
        warning: {
            default: false
        },
        warning_text: {
            default: null
        }
    },
    mounted() {
        this.value = this.model
    },
    watch: {
        value: function (value) {
            this.$emit('update:nation', this.model)
        }
    },
}
</script>
