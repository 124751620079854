<template>
    <InputSelect
        input_class="vx-col w-full"
        :model.sync="value"
        :placeholder="placeholder"
        :label="label"
        :options="options"
        :disabled="disabled"
        :warning_text="warning_text"
        :warning="warning"
    />
</template>

<script>
export default {
    
    props: {
        placeholder: {
            type : String,
            default : "Choose a Gender",
        },
        label: {
            type : String,
            default : "Gender",
        },
        selectedValue: {
            default : null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            default: false
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            is_success : null,
            is_invalid : null,
            value: this.selectedValue,
        }
    },
    mounted() {
        this.value = this.selectedValue
    },
    watch: {
        value: function (new_value) {
            if(new_value){
                this.$emit('update:selectedValue', new_value)
            }else{
                this.$emit('update:selectedValue', new_value)
            }
        }
    }
}
</script>
