<!-- =========================================================================================
    Description :  Letstream TextField Email Component
    Author: @letstream
    Events: 
    - @error
    - @success
========================================================================================== -->

<template>
        <vs-input
            class="textField"
            :class = "classAnother"
            :label= label
            :type = type
            v-model="email"
            :requied = required
            :placeholder = "placeholder"
            :success = successEmail
            :danger= invalidEmail
            :success-text= successText
            :danger-text= dangerText
        />
</template>
<script>

export default {
    props: {
        email : {
            type: String,
            default: '',
        },
        type : {
            type: String,
            default: '',
        },
        label : {
            type: String,
            default: '',
        },
        required : {
            type : Boolean,
            default: true,
        },
        classAnother : {
            type : String,
            default : '', 
        },
        placeholder : {
            type : String,
            default : '', 
        },
        successText : {
            type: String,
            default: '',
        },
        dangerText : {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            successEmail : null,
            invalidEmail : null,
        }
    },
    watch: {
        email: function (value) {
            if(this.reg.test(value)){
                this.successEmail = true
                this.invalidEmail = false
                this.$emit('input', value)
                this.$emit('success', 'Success Msg')
            }else{
                this.successEmail = false
                this.invalidEmail = true
                this.$emit('input', '')
                this.$emit('error', 'Error Msg')
            }
        }
    },
}
</script>

<style lang="scss">
    .textField{
        width: 100%;
    }

</style>
