===================
Letstream Select Gender Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - selectedGender (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input

====================


<template>
    <InputSelect
        input_class="vx-col w-full"
        :model.sync="value"
        :warning="warning"
        :warning_text="warning_text"
        :placeholder="placeholder"
        :label="label"
        :options="options"
        :disabled="disabled"
    />
</template>

<script>
export default {
    
    props: {
        placeholder: {
            type : String,
            default : "Choose a Gender",
        },
        label: {
            type : String,
            default : "Gender",
        },
        selectedGender : {
            default : null,
        },
        successText : {
            type: String,
            default: null,
        },
        dangerText : {
            type: String,
            default: 'This value is invalid',
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            is_success : null,
            is_invalid : null,
            value: this.selectedGender,
            options: [
                {"label": "Male", "value": "male"},
                {"label": "Female", "value": "female"},
                {"label": "Other/Do not specify", "value": "other"},
            ]
        }
    },
    mounted() {
        this.value = this.selectedGender
    },
    watch: {
        value: function (new_value) {
            if(new_value){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:selectedGender', new_value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:selectedGender', new_value)
            }
        }
    }
}
</script>
