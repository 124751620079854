===================
Letstream Input Llpin Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - ifsc_code (Required): Sate to save given ifsc input
 - bank_name (Required): Sate to save given bank name input
 - account_number (Required): Sate to save given bank name input
 - account_type (Required): Sate to save given account type input
 - account_type_other (Required): Sate to save given account type other input
 - label_ifsc (Required)
 - label_bank_name (Required)
 - label_account_number (Required)
 - label_account_type (Required)
 - label_account_type_other (Required)
 - placeHolder_ifsc (Required)
 - placeHolder_bank_name (Required)
 - placeHolder_account_number (Required)
 - placeHolder_account_type_other (Required)
 - successText_ifsc (Required): Text message for correct input of ifsc
 - successText_bank_name (Required): Text message for correct input of bank name
 - successText_account_number (Required): Text message for correct input of bank name
 - successText_account_type (Required): Text message for correct input of account type
 - successText_account_type_other (Required): Text message for correct input of account type other
 - dangerText_ifsc (Required): Text message for incorrect input of ifsc
 - dangerText_bank_name (Required): Text message for incorrect input of bank name
 - dangerText_account_number (Required): Text message for incorrect input of bank name
 - dangerText_account_type (Required): Text message for incorrect input of account type
 - dangerText_account_type_other (Required): Text message for incorrect input of account type other
 - custom_class_ifsc_code: Custom class IFSC CODE
 - custom_class_account_number: Custom class for Account Number 
 - custom_class_bank_name: Custom class for Bnak Name 
 - custom_class_account_type: Custom class for Account Type 
 - custom_class_account_type_other: Custom class for Account Type 

 - warning_ifsc_code
 - warning_text_ifsc_code
 - warning_account_number
 - warning-text_account_number
 - warning_bank_name
 - warning-text_bank_name
 - warning_bank_account_type
 - warning-text_bank_account_type
 - warning_bank_account_type_other
 - warning-text_bank_account_type_other

====================


<template>
<div class="vx-row">
        <template v-if="!disabled">
            <HelpdeskAlert url="https://desk.zoho.in/portal/firmsapconsultingllp/kb/articles/adding-bank-details-29-4-2020" />
        </template>
        <vs-input 
            v-model="value.ifsc_code"
            :warning="warning_ifsc_code ? true : false"
            :warning-text="warning_text_ifsc_code ? warning_text_ifsc_code : null"
            :label="label_ifsc"
            :success="is_success_ifsc"
            :danger="is_invalid_ifsc"
            :class="custom_class_ifsc_code"
            :success-text="successText_ifsc"
            :danger-text="dangerText_ifsc"
            :placeholder="placeHolder_ifsc"
            :disabled="disabled"
        />
        <vs-input 
            v-model="value.account_number"
            :warning="warning_account_number ? true : false"
            :warning-text="warning_text_account_number ? warning-text_account_number : null"
            :label="label_account_number"
            :success="is_success_account_number"
            :danger="is_invalid_account_number"
            :class="custom_class_account_number"
            type="Number"
            :success-text="successText_account_number"
            :danger-text="dangerText_account_number"
            :placeholder="placeHolder_account_number"
            :disabled="disabled"
        />
        <vs-input 
            v-model="value.bank_name"
            :warning="warning_bank_name ? true : false"
            :warning-text="warning_text_bank_name ? warning-text_bank_name : null"
            :disabled="disable_bank_name || disabled"
            :label="label_bank_name"
            :success="is_success_bank_name"
            :danger="is_invalid_bank_name"
            :class="custom_class_bank_name"
            :success-text="successText_bank_name"
            :danger-text="dangerText_bank_name"
            :placeholder="placeHolder_bank_name"
        />
        <InputSelect
            :model.sync="value.account_type"
            :warning="warning_bank_account_type ? true : false"
            :warning_text="warning_text_bank_account_type ? warning-text_bank_account_type : null"
            :label="label_account_type"
            :container_class="custom_class_account_type"
            placeholder="Please select a value"
            :options="[{value:'savings', label: 'Savings'},{value:'current_account', label: 'Current Account'},{value:'overdraft_account', label: 'Overdraft Account'},{value:'other', label: 'Other'}]"
            :disabled="disabled"
        />
        <vs-input 
            v-if="is_account_type_other"
            v-model="value.account_type_other"
            :warning="warning_bank_account_type_other ? true : false"
            :warning-text="warning_text_bank_account_type_other ? warning_text_bank_account_type_other : null"
            :label="label_account_type_other"
            :success="is_success_account_type_other"
            :danger="is_invalid_account_type_other"
            :class="custom_class_account_type_other"
            :success-text="successText_account_type_other"
            :danger-text="dangerText_account_type_other"
            :placeholder="placeholder_account_type_other"
            :disabled="disabled"
        />
</div>
</template>

<script>

import Axios from 'axios';

export default {
    props: {
        classAnother : {
            type : String,
            default : '', 
        },
        ifsc_code : {
            type: String,
            default: '',
        },
        account_number : {
            type: String,
            default: '',
        },
        bank_name : {
            type: String,
            default: '',
        },
        account_type : {
            default: null,
        },
        account_type_other : {
            type: String,
            default: '',
        },
        label_ifsc : {
            type: String,
            default: 'Enter IFSC Number',
        },
        label_bank_name : {
            type: String,
            default: 'Enter Bank Name',
        },
        label_account_type : {
            type: String,
            default: 'Select type of Account',
        },
        label_account_type_other : {
            type: String,
            default: 'Input the Other Account Type',
        },
        label_account_number : {
            type: String,
            default: 'Enter Account Number',
        },
        successText_ifsc : {
            type: String,
            default: 'IFSC Number Valid',
        },
        successText_bank_name : {
            type: String,
            default: 'Bank Name Valid',
        },
        successText_account_number : {
            type: String,
            default: 'Account Number Valid',
        },
        successText_account_type : {
            type: String,
            default: 'Account Type Valid',
        },
        successText_account_type_other : {
            type: String,
            default: 'Other Account Type Valid',
        },
        dangerText_ifsc : {
            type: String,
            default: 'IFSC Number Not Valid',
        },
        dangerText_bank_name : {
            type: String,
            default: 'Bank Name Not Valid',
        },
        dangerText_account_number : {
            type: String,
            default: 'Account Number Not Valid',
        },
        dangerText_account_type : {
            type: String,
            default: 'Account Type Not Valid',
        },
        dangerText_account_type_other : {
            type: String,
            default: 'Other Account Type Not Valid',
        },
        placeHolder_ifsc : {
            type: String,
            default: 'Enter IFSC Number',
        },
        placeHolder_account_number : {
            type: String,
            default: 'Enter Account Number',
        },
        placeHolder_bank_name : {
            type: String,
            default: 'Enter Bank Name',
        },
        placeholder_account_type : {
            type: String,
            default: '',
        },
        placeholder_account_type_other : {
            type: String,
            default: 'Please Specify Other',
        },
        custom_class_ifsc_code : {
            type: String,
            default: 'w-full',
        },
        custom_class_account_number : {
            type: String,
            default: 'w-full',
        },
        custom_class_bank_name : {
            type: String,
            default: 'w-full',
        },
        custom_class_account_type : {
            type: String,
            default: 'w-full',
        },
        custom_class_account_type_other : {
            type: String,
            default: 'w-full',
        },
        warning_ifsc_code : {
            type: Boolean,
            default: false,
        },
        warning_text_ifsc_code : {
            type: String,
        },
        warning_account_number : {
            type: Boolean,
            default: false,
        },
        warning_text_account_number : {
            type: String,
        },
        warning_bank_name : {
            type: Boolean,
            default: false,
        },
        warning_text_bank_name : {
            type: String,
        },
        warning_bank_account_type : {
            type: Boolean,
            default: false,
        },
        warning_text_bank_account_type : {
            type: String,
        },
        warning_bank_account_type_other : {
            type: Boolean,
            default: false,
        },
        warning_text_bank_account_type_other : {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            disable_bank_name: true,
            is_account_type_other : false,
            reg_ifsc: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
            reg_account_number: /^\d{9,18}$/,
            is_success_ifsc : null,
            is_invalid_ifsc : null,
            is_success_bank_name : null,
            is_invalid_bank_name : null,
            is_success_account_number : null,
            is_success_account_type_other : null,
            is_invalid_account_number : null,
            is_invalid_account_type_other : null,
            value: {
                ifsc_code : this.ifsc_code,
                account_number : this.account_number,
                bank_name : this.bank_name,
                account_type : this.account_type,
                account_type_other : this.account_type_other,
            }
        }
    },
    mounted() {
        this.value.ifsc_code = this.ifsc_code
        this.value.account_number = this.account_number
        this.value.bank_name = this.bank_name
        this.value.account_type = this.account_type
        this.value.account_type_other = this.account_type_other
    },
    watch: {
        'value.ifsc_code': function (value) {
            if(!value || (value && value.toString().trim().length == 0)){
                this.is_success_ifsc = false
                this.is_invalid_ifsc = false
                return
            }

            if(this.reg_ifsc.test(value)){
                this.is_success_ifsc = false
                this.is_invalid_ifsc = false
            
                this.$emit('success', this.successText_ifsc)
                this.$emit('update:ifsc_code', value)
                
                let ifsc_url = 'https://ifsc.razorpay.com/'+ value
                let $this = this
                
                Axios.get(ifsc_url).then(
                    response => {
                        $this.disable_bank_name = true
                        $this.is_success_ifsc = true
                        $this.value.bank_name = response.data['BANK']
                    }, 
                    error => {
                        $this.value.bank_name = null
                        $this.is_invalid_ifsc = true
                        $this.disable_bank_name = false
                    }
                )
            } else{
                this.is_success_ifsc = false
                this.is_invalid_ifsc = true
                
                this.$emit('error', this.dangerText_ifsc)
                this.$emit('update:ifsc_code', value)
            }
        },
        'value.bank_name': function(value) {
            this.$emit('update:bank_name', value)
        },
        'value.account_type': function (value) {
            if(!value || (value && value.toString().trim().length == 0)){
                this.is_success = false
                this.is_invalid = false
                return
            }

            if (value=='other'){
                this.is_account_type_other=true
            }
            else{
                this.is_account_type_other=false
            }
            if(this.reg_ifsc.test(value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:account_type', value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:account_type', value)
            }
        },
        'value.account_type_other': function (value) {
            if(!value || (value && value.toString().trim().length == 0)){
                this.is_success_account_type_other = false
                this.is_invalid_account_type_other = false
                return
            }
            this.is_success_account_type_other = true
            this.is_invalid_account_type_other = false

            this.$emit('success', this.successText_account_type_other)
            this.$emit('update:account_type_other', value)
        },
        'value.account_number': function (value) {
            if(!value || (value && value.toString().trim().length == 0)){
                this.is_success_account_number = false
                this.is_invalid_account_number = false
                return
            }

            if(this.reg_account_number.test(value)){
                this.is_success_account_number = true
                this.is_invalid_account_number = false
                this.$emit('success', 'this.successText_account_number')
                this.$emit('update:account_number', value)
            }else{
                this.is_success_account_number = false
                this.is_invalid_account_number = true
                this.$emit('error', 'this.dangerText_account_type_other')
                this.$emit('update:account_number', value)
            }
        },
    }
}
</script>