<!--=========================================================================================
    Description: Letstream TextField Pincode Component
    Author: @letstream
    Events: 
    - @error
    - @success
==========================================================================================-->

<template>
        <vs-input
            class = "textField"
            :class = "classAnother"
            :label= label
            :type = type
            :requied = true
            :placeholder = "placeholder"
            v-model = "value_pincode"
            :success = successPincode
            :danger= dangerPincode
            :success-text = successText
            :danger-text = dangerText
        />
</template>
<script>
export default {
    props: {
        pincode : {
            type: String,
            default: '',
        },
        type : {
            type: String,
            default: '',
        },
        label : {
            type: String,
            default: '',
        },
        // required : {
        //     type : Boolean,
        //     default: true,
        // },
        classAnother : {
            type : String,
            default : '', 
        },
        placeholder : {
            type : String,
            default : '', 
        },
        successText : {
            type: String,
            default: '',
        },
        dangerText : {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            successPincode : null,
            dangerPincode : null,
            value_pincode: this.pincode
        }
    },
    mounted() {
        this.value_pincode = this.pincode
    },
    watch: {
        value_pincode: function (value, oldV) {
            if(value == oldV) return

            if(!isNaN(value)){
                this.successPincode = true
                this.dangerPincode = false
                this.$emit('input', value)
                this.$emit('update:pincode', value)
                this.$emit('success', 'Success Msg')
            }else{
                this.successPincode = false
                this.dangerPincode = true
                this.$emit('input', '')
                this.$emit('update:pincode', value)
                this.$emit('error', 'Error Msg')
            }
        }
    },
}
</script>

<style lang="scss">
    .textField{
        width: 100%;
    }

</style>
