import auth from "@/auth/authService";
import apiendpoints from "@/../apiendpoints";

export default {
    isUserLoggedIn: () => {
        let isAuthenticated = false;

        return new Promise(async (resolve, reject) => {
          console.log("Executing")
          isAuthenticated = await auth.isAuthenticated()
          console.log("Executed")
          resolve(isAuthenticated != false && localStorage.getItem('userInfo') != null)
        })
    },
    apiendpoints: apiendpoints,
    userRole: localStorage.getItem('userRole') || 'public',
    userInfo: null
}