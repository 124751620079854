<!-- =========================================================================================
    Description :  Letstream Nation Select Menu Component
    Author: @letstream
========================================================================================== -->

<template>
    <div :class="container_class">
        <InputSelect 
            :model.sync="value_state" 
            :label="label_state"
            placeholder="Select State"
            :options="stateAndDistrictOptions"
            :container_class="class_state"
            :disabled="disabled"
        />
        <InputSelect 
            :model.sync="value_district" 
            :label="label_district"
            placeholder="Select District"
            :options="districtFiltered"
            :container_class="class_district"
            :disabled="disabled"
        />
    </div>
</template>
<script>
import stateAndDistrictOptions from "./districts.json"

export default {
    data() {
        return {
            value_state: this.state,
            value_district: this.district,
            stateAndDistrictOptions: stateAndDistrictOptions,
            districtFiltered: [],
        }
    },
    props: {
        container_class: {
            default: "w-full"
        },
        class_state : {
            default : 'w-full select-large', 
        },
        class_district : {
            default : 'w-full select-large', 
        },
        label_state: {
            default : "State",
        },
        label_district: {
            default : "District",
        },
        state: {
            default : null,
        },
        district: {
            default : null,
        },
        warning_state: {
            default: false
        },
        warning_text_state: {
            default: null
        },
        warning_district: {
            default: false
        },
        warning_text_district: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.value_district = this.district
        this.value_state = this.state
        this.filterDistricts(this.value_state, false)
    },
    watch: {
        state(value){
            this.value_state = this.state
        },
        district(value) {
            this.value_district = this.district
        },
        value_state: function (value, oldV) {
            if(value == oldV) return
            this.filterDistricts(value)
            this.$emit('update:state', value)
        },
        value_district: function (value, oldV) {
            if(value == oldV) return
            this.$emit('update:district', value)      
        },
    },
    methods: {
        filterDistricts(value, reset=true) {
            if(!value) return

            for(let i in this.stateAndDistrictOptions){
                if(this.stateAndDistrictOptions[i].value == value){
                    this.districtFiltered = this.stateAndDistrictOptions[i].districts;
                    if(this.value_district){
                        this.districtFiltered.forEach((d,i) => {
                            if(d.value == this.value_district)
                                reset = false
                        })
                        if (reset) this.value_district = null
                    }
                }
            }
        }
    }
}
</script>
