<!-- =========================================================================================
    Description :  Letstream TextField Phone Number Component
    Author: @letstream
    Events: 
    - @error
    - @success
========================================================================================== -->

<template>
        <vs-input
            class="textField"
            :class = "classAnother"
            :label= label
            :type = type
            :requied = required
            :placeholder = "placeholder"
            v-model="phoneNumber"
            :success = successNumber
            :danger= dangerNumber
            :success-text= successText
            :danger-text= dangerText
        />
</template>
<script>

export default {
    props: {
        phoneNumber : {
            type: String,
            default: '',
        },
        type : {
            type: String,
            default: '',
        },
        label : {
            type: String,
            default: '',
        },
        required : {
            type : Boolean,
            default: true,
        },
        classAnother : {
            type : String,
            default : '', 
        },
        placeholder : {
            type : String,
            default : '', 
        },
        successText : {
            type: String,
            default: '',
        },
        dangerText : {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            successNumber : null,
            dangerNumber : null,
        }
    },
    computed: {
        
        
    },
    watch: {
        phoneNumber: function (value) {
            if(value.length == 10 && !isNaN(value)){
                this.successNumber = true
                this.dangerNumber = false
                this.$emit('input', value)
                this.$emit('success', 'Success Msg')
            }else{
                this.successNumber = false
                this.dangerNumber = true
                this.$emit('input', '')
                this.$emit('error', 'Error Msg')
            }
        }
    },
    methods: {
       
    },
    components: {
        // VuePerfectScrollbar,
    }
}
</script>

<style lang="scss">
    .textField{
        width: 100%;
    }

</style>
