// import auth0 from 'auth0-js';
import EventEmitter from 'events';
import { api } from '../utils/requests';
// import authConfig from '../../auth_config.json';

import store from "../store/store"

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = 'loggedIn';

const tokenExpiryKey = 'tokenExpiry';
const loginEvent = 'loginEvent';

class AuthService extends EventEmitter {
    idToken = null;
    profile = null;
    tokenExpiry = null;

    renewTokens() {
        // reject can be used as parameter in promise for using reject
        return new Promise((resolve) => {
            if (localStorage.getItem(localStorageKey) !== "true") {
                // return reject("Not logged in");
            }
            
            // this.localLogin(authResult);
            // resolve(authResult);

            // webAuth.checkSession({}, (err, authResult) => {
            //     if (err) {
            //         // reject(err);
            //     } else {
            //         this.localLogin(authResult);
            //         resolve(authResult);
            //     }
            // });
        });
    }

    logOut() {
        // localStorage.removeItem('token');
		localStorage.removeItem('userInfo');
        localStorage.removeItem('userRole');

        this.emit(loginEvent, { loggedIn: false });
    }

    isAuthenticated() {
        return new Promise( (resolve, reject) => {
            if (!store.state.auth.userInfo) {
                api.get_user({id: 'me', authenticated: true}).then( (res) => {
                    store.dispatch('auth/updateAuthenticatedUser', res.user)
                    store.dispatch('auth/updateRole', {role: res.role})
                    resolve(res)
                }, (err) => {
                    store.dispatch('auth/logout')
                    reject(err)
                })
            } else {
                resolve()
            }
        })
    }
}

export default new AuthService();
