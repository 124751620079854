export default {
	UPDATE_AUTHENTICATED_USER(state, data) {
		localStorage.setItem('userInfo', JSON.stringify(data))
    state.userInfo = data
  },
  UPDATE_AUTHENTICATED_ROLE(state, data) {
    localStorage.setItem('userRole', data);
    state.userRole = data
  },
  LOGOUT_USER(state, data) {
    localStorage.removeItem('token');
		localStorage.removeItem('userInfo');
    localStorage.removeItem('userRole');
  },
  UPDATE_USER_TOKEN (state, token) {
      localStorage.setItem('token', token)
  }
}