===================
Letstream Input Gst Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - gst (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input
 - placeHolder (Required)

====================


<template>
    <span>
        <vs-input 
            v-model="value"
            :label="label"
            :success="is_success"
            :danger="is_invalid"
            class="w-full"
            :success-text="successText"
            :danger-text="dangerText"
            :warning="warning"
            :placeholder="placeholder"
            :disabled="disabled"
        />
        <small class="text-warning d-block" v-if="warning">{{ warning_text }}</small>
    </span>
</template>

<script>
export default {
    props: {
        gst : {
            default: null,
        },
        label : {
            default: 'Enter GST Number',
        },
        successText : {
            type: String,
            default: 'GSTIN is Valid',
        },
        dangerText : {
            type: String,
            default: 'GSTIN is Invalid',
        },
        placeholder : {
            type: String,
            default: 'Enter your 15 Digit GST Number',
        },
        warning: {
            default: false
        },
        warning_text: {
            default: null
        },
        disabled: {
            default: false
        }
    },
    data() {
        return {
            reg: /^([0-2][0-9]|[3][0-7])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/,
            is_success : null,
            is_invalid : null,
            value: null
        }
    },
    mounted() {
        this.value = this.gst
    },
    watch: {
        gst: function(newV, oldV) {
            if(oldV == newV) return
            this.value = this.gst
        },  
        value: function (value) {
            if(!value || (value && value.toString().trim().length == 0)){
                this.is_success = false
                this.is_invalid = false
                return
            }
            if(this.reg.test(value)){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:gst', value)
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:gst', value)
            }
        }
    }
}
</script>