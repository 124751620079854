<template>
    <vs-input
        v-model="value"
        :placeholder="placeholder"
        :label="label"
        type="Number"
        :max="maximum_year"
        :min="minimum_year"
        :class="class_custom"
        :warning="warning"
        :warning-text="warning_text"
        :disabled="disabled"
        @change="setValue"
    />
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {  
    props: {
        placeholder: {
            type : String,
            default : "",
        },
        label: {
            type : String,
            default : "",
        },
        class_custom : {
            type: String,
            default: 'w-full',
        },
        warning : {
            type: Boolean,
            default: false
        },
        warning_text : {
            default: null
        },
        model: {
            required: true,
            default: null
        },
        minimum_year: {
            default: null
        },
        maximum_year: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Datepicker
    },
    data() {
        return {
            value: this.model,
        }
    }, 
    mounted() {
        this.value = this.model 
    },
    watch: {
        model: function (value, oldV) {
            if(value == oldV) return
            this.value = value
        }
    },
    methods: {
        setValue(){
            if(this.minimum_year){
                this.value=Math.max(this.value, this.minimum_year) 
            }
            if(this.maximum_year){
                this.value=Math.min(this.value, this.maximum_year)
            }
            // this.value=this.value
            this.$emit('update:model', this.value)
        }
    }
}
</script>
