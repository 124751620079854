import { LetstreamAPI } from '@letstream/ls-api-client/api'
import { APIRequest } from '@letstream/ls-api-client';
import { NetworkError, UnauthorizedError, AccessForbiddenError, ServerError, UnknownError, NotFoundError } from '@letstream/ls-api-client/errors';
import apiendpoints from '../../apiendpoints';
import store from "../store/store"
import { v4 as uuidv4 } from 'uuid';

function getToken() {
    return localStorage.getItem('token')
}

function getCookie(){
    let csrf = document.cookie.split(";").filter((v) => v.split("=")[0].trim() == 'csrftoken')
    if(csrf.length)
        return csrf[0].split("=")[1]
}

const connection_pool_limit = 10

if (!window.ls_requests) {
    // Requests Pool Manager
    window.ls_requests = []
}

function add_ls_requests(t) {window.ls_requests.push(t)}
function remove_ls_requests(t) {
    let i = window.ls_requests.findIndex(v => v == t)
    if (i >= 0 ) window.ls_requests.splice(i, 1)
}

export const api = new LetstreamAPI({
    base_url: apiendpoints.api_base_noslash,
    token: getToken,
    error_handler: error_handler,
    csrf: getCookie,
    base_auth_url: apiendpoints.auth_base
})

export function Request(instance, request_method, url, params, data, headers={}, add_authorization=true) {

    if (!headers) headers = {}

    if (store.state.organisation.organisation) {
        headers['Organisation'] = store.state.organisation.organisation.token
    }
    if (store.state.organisation.entity) {
        headers['OrganisationEntity'] = store.state.organisation.entity.id
    }

    return new Promise((resolve, reject) => {
        let uuid = uuidv4();

        function do_request() {
            if (window.ls_requests.length >= connection_pool_limit) {
                // console.log("Request ", uuid, " Waiting for request pool, ", connection_pool_limit)
                setTimeout(do_request, 50)
            } else {
                // console.log("Executing Request for ", uuid)
                add_ls_requests(uuid)
                APIRequest(request_method, url, params, data, headers, add_authorization, getToken, getCookie).then( (res) => {
                    remove_ls_requests(uuid)
                    if (res.version) {
                        store.dispatch('updateServerVersion', res.version)
                    }
                    if(res.data){
                        resolve(res.data)
                    }else{
                        resolve(res)
                    }

                }).catch( (e) => {
                    remove_ls_requests(uuid)
                    if(api.is_data_error(e)){
                        reject(e.data)
                    } else {
                        error_handler(e, reject, [], {instance: instance})
                    }
                })
            }
        }
        do_request()
    })
}

function error_handler(err, reject, ignore_errors, params={instance: null}){

    if(err instanceof NetworkError ){
        if(params.instance)
            params.instance.$vs.notify({
                time: 4000,
                title: 'Network Error',
                text: 'An Error occurred while processing your request. Please Retry.',
                color: 'danger'
            })
        reject(false)
    } else if(err instanceof ServerError ){
        if (params.instance)
            params.instance.$vs.notify({
                time: 4000,
                title: 'Server Error',
                text: 'An Error occurred while processing your request. Please Retry.',
                color: 'danger'
            })
        reject(false)
    } else if(err instanceof UnauthorizedError) {
        if (params.instance){
            params.instance.$store.dispatch('auth/logout')
            params.instance.$router.push({'name': 'dashboard-analytics'})
        }
        // reject(false)
    } else if(err instanceof AccessForbiddenError) {
        if (params.instance){
            params.instance.$vs.notify({
                time: 4000,
                title: 'Forbidden',
                text: 'You do not have permissions to perform the requested operation.',
                color: 'danger'
            })
            params.instance.$router.push({'name': 'page-not-authorized'})
        }
        reject(false)
    } else if(err instanceof UnknownError) {
        if (params.instance)
            params.instance.$vs.notify({
                time: 4000,
                title: 'Unknown Error',
                text: 'An unknown error occurred. Please try again.',
                color: 'danger'
            })
        reject(false)
    } else {
        reject(false)
    }
}
