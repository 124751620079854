<!-- =========================================================================================
    Description :  Letstream Nation Select Menu Component
    Author: @letstream
========================================================================================== -->

<template>
    <InputSelect 
        :model.sync="value_district" 
        :label="label_district"
        :placeholder="placeholder"
        :options="options"
        :container_class="class_district"
        :warning-text="warning_text"
        :warning="warning"
    />
</template>
<script>

import stateAndDistrictOptions from "./districts.json"

let district_list = []
let state_district_options= stateAndDistrictOptions
state_district_options.forEach(v => {
    v['districts'].forEach(v2 =>{
        district_list.push({
            'label': v2['label'] + " (" + v['label'] + ")",
            'value': v2['value'] + "__" + v['value']
        })
    })
})
export default {
    data() {
        return {
            value_district: this.district,
            options: district_list
        }
    },
    props: {
        container_class: {
            default: "w-full"
        },
        class_district : {
            default : 'w-full select-large', 
        },
        label_district: {
            default : "District",
        },
        placeholder: {
            default : null,
        },
        district: {
            default : null,
        },
        warning_text: {
            default: null
        },
        warning: {
            type: Boolean,
            default: false
        },
    },
    mounted(){
        
    },
    watch: {
        value_district: function(value, oldV){
            if(value == oldV) return
            this.$emit('update:district', value)
        }
    }
}
</script>
