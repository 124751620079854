<!-- =========================================================================================
    Description :  Letstream Nation Select Menu Component
    Author: @letstream


========================================================================================== -->

<template>
    <div :class="container_class">
        <InputSelect 
            :model.sync="value" 
            :input_class="class_nationality" 
            :label="label"
            :options="getCountries"
            :warning="warning"
            :warning_text="warning_text"
            placeholder="Please select a country"
            :disabled="disabled"
        />
    </div>
</template>

<script>

import Countries from "./countries.json";

export default {
    props: {
        nationality: {
            required: true
        },
        container_class: {
            type: String,
            default: null
        },
        class_nationality : {
            type : String,
            default : 'w-full', 
        },
        label: {
            type : String,
            default : "Nationality",
        },
        warning: {
            type: Boolean,
            default: false
        },
        warning_text: {
            default: null
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            value: this.nationality,
        }
    },
    mounted() {
        this.value = this.nationality
    },
    computed: {
        getCountries() {return Countries}
    },
    watch: {
        nationality(value, oldV){
            if(value == oldV) return
            this.value = value
        },
        value(value, oldV) {
            if(value == oldV) return
            this.$emit('update:nationality', value)
        }
    },
}
</script>
