===================
Letstream Select Date Component
Author: @letstream

Events: 
 - @error
 - @success

Props:
 - selectedDate (Required): Sate to save given input
 - label (Required)
 - successText (Required): Text message for correct input
 - dangerText (Required): Text message for incorrect input

====================

<template>
    <div :class="position">
        <label class="vs-input--label" v-if="label">{{label}}</label>
        <datepicker 
            placeholder="DD MMM YYYY"
            :class="classAnother" 
            v-model="value"
            :format="getFormattedDate"
            :disabled-dates="disabled_dates"
            :typeable="true"
            :clear-button="true"
            :disabled="disabled"
        ></datepicker>
        <span style="color:red" v-if="warning">{{warning_text}}</span>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

export default {  
    props: {
        placeHolder: {
            type : String,
            default : "",
        },
        label: {
            type : String,
            default : "",
        },
        successText : {
            type: String,
            default: '',
        },
        dangerText : {
            type: String,
            default: '',
        },
        classAnother : {
            type: String,
            default: '',
        },
        warning : {
            type: Boolean,
            default: false
        },
        warning_text : {
            default: null
        },
        selectedDate: {
            required: true,
            default: null
        },
        minimum_date: {
            default: null
        },
        position: {
            default: "bottom"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allow_all_dates: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Datepicker
    },
    data() {
        let today = new Date(Date.now())
        // today.setDate(today.getDate() + 1)
        
        return {
            is_success : null,
            value: null,
            is_invalid : null,
            disabled_dates: this.allow_all_dates ? {} : {
                from: today,
                to: this.minimum_date
            }
        }
    },
    mounted() {
        if(this.selectedDate){
            if(typeof this.selectedDate == "string")
                this.value = new Date(this.selectedDate)
            else
                this.value = this.selectedDate
        }
        if(this.minimum_date && !this.allow_all_dates){
            if(typeof this.minimum_date == "string")
                this.disabled_dates.to = new Date(this.minimum_date)
            else
                this.disabled_dates.to = this.minimum_date
        }
    },
    watch: {
        minimum_date: function(value, oldV){
            if(value == oldV) return
            if(this.allow_all_dates) return
            if(value){
                if(typeof value == "string")
                    this.disabled_dates.to = new Date(value)
                else
                    this.disabled_dates.to = value
            }
        },
        selectedDate: function (value, oldV) {
            if(value == oldV) return
            if(value){
                if(typeof value == "string")
                    this.value = new Date(value)
                else
                    this.value = value
            }
        },
        value: function (value, oldV) {
            if(value == oldV) return
            if(value){
                this.is_success = true
                this.is_invalid = false
                this.$emit('success', this.successText)
                this.$emit('update:selectedDate', this.formatDate(value))
            }else{
                this.is_success = false
                this.is_invalid = true
                this.$emit('error', this.dangerText)
                this.$emit('update:selectedDate', this.formatDate(value))
            }
        }
    },
    methods: {
        formatDate(date) {
            if(!date) return null
            let d = date
            if(typeof date == 'string')
                d = new Date(date)
            return moment(date).format('YYYY-MM-DD')
        },
        getFormattedDate(date) {
            return moment(date).format('DD MMM YYYY')
        }
    }
}
</script>
<style lang="scss">
    .vdp-datepicker__clear-button {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        height: 1.5em;
        font-size: 1.5em;
    }
    .top .vdp-datepicker__calendar  {
        bottom: 100%;
    }
</style>
