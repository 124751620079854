import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

import store from '../store/store';

let firebaseConfig = process.env.VUE_APP_FIREBASE_CONFIG
let app = null
let messaging = null

if (!firebaseConfig) {
  console.error("Firebase Config was not found. ")
} else {
  firebaseConfig = JSON.parse(firebaseConfig);

  // Initialize Firebase
  // if (!firebase.apps.length)
  app = firebase.initializeApp(firebaseConfig, "legully");
  // else
  //   firebase.app()
  messaging = firebase.messaging(app)
}

export default messaging;

export function requestPermission() {
  console.log("Requesting Permissions")
  if (!messaging) {
    console.error("FCM not initialized")
    return
  }
  messaging.getToken().then(res => {
    store.dispatch('sendFCMToken', res)
  }).catch(err => {
    console.log(err)
  })
}
