<template>
    <div class="vs-component vs-con-input-label vs-input vs-input-primary">
        <label for="" class="vs-input--label" v-if="label">{{ label }}</label>
        <div class="vs-con-input">
            <input ref="autocomplete" onfocus="value=''" type="text" name="store_address" class="search-location vs-inputx vs-input--input normal hasIcon" style="border: 1px solid rgba(0, 0, 0, 0.2);" :value="value">
            <i class="vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon-map null icon-no-border"></i><!---->
        </div>
        <span></span>
    </div>
</template>
<script>
  export default {
    name: 'ls-gmaps-autocomplete',
    props: {
        name: null,
        label: null,
        placeholder: null,
        value: null
    },
    mounted() {
        let googlescript = document.createElement('script')
        googlescript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBeUxCx5R3HdeeCJn1vDDukFafM3i3iYZI&libraries=places')
        let $this = this
        googlescript.addEventListener('load', () => {
            $this.init()
        })
        document.head.appendChild(googlescript)
    },
    methods: {
        init(){
            this.autocomplete = new google.maps.places.Autocomplete(
                (this.$refs.autocomplete),
                {types: ['geocode']}
            )
            this.autocomplete.addListener('place_changed', ()=>{
                let place = this.autocomplete.getPlace()
                this.$emit('change', place.formatted_address)
            })
        }
    }
  }

</script>
