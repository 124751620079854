export default {
    UPDATE_ACTIVE_ORGANISATION(state, organisation) {
        localStorage.setItem('last_organisation', JSON.stringify(organisation))
        state.organisation = organisation
    },
    UPDATE_ORGANISATION_USER(state, user) {
      localStorage.setItem('last_organisation_user', JSON.stringify(user))
      state.organisation_user = user
    },
    UPDATE_ACTIVE_ENTITY(state, entity) {
        if (entity)
            localStorage.setItem('last_entity', JSON.stringify(entity))
        else
            localStorage.removeItem('last_entity')

        state.entity = entity
    },

}
