import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// Fontawesome Init
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faCheckCircle,
    faBell,
    faExclamationCircle,
    faTimesCircle,
    faBellSlash,
    faEdit
} from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle)
library.add(faBell)
library.add(faExclamationCircle)
library.add(faTimesCircle)
library.add(faBellSlash)
library.add(faEdit)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false


// axios
import axios from 'axios'
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


// Firebase
import '@/firebase/firebaseConfig'


// Auth0 Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuesax Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);


// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
        key: 'YOUR_API_KEY',
        libraries: 'places', // This is required if you use the Auto complete plug-in
    },
})

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// Register Service Worker
import './registerServiceWorker'

// FCM Settings
// import * as firebase from "firebase";
// const firebaseConfig = {
//     apiKey: "AIzaSyAcuijkxneRkpbOibr5g_c5P0nWdqFobIQ",
//     authDomain: "legully-main.firebaseapp.com",
//     databaseURL: "https://legully-main.firebaseio.com",
//     projectId: "legully-main",
//     storageBucket: "legully-main.appspot.com",
//     messagingSenderId: "1072717793351",
//     appId: "1:1072717793351:web:56eb91e809955af58e0962",
//     measurementId: "G-HGV57YLM34",
// };

// if (!firebase.apps.length)
//     firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging();
// messaging.usePublicVapidKey("BAl3KY7dTKcvtg_QVtXOYGkxMLHStoZPBoED_fTNXxye1Wcf_LwT0Kn9TbwXvIc1MMZk-XX7X4r95uqGWUI998Y"); // 1. Generate a new key pair

// Vue.prototype.$fcmMessaging = messaging

// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if(process.env.NODE_ENV == 'production') {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing({attachProps: true})],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0,
      });
}

// Google Analytics
import VueGtag from "vue-gtag";
if(process.env.NODE_ENV == 'production') {
    Vue.use(VueGtag, {
        config: { id: "G-5E7JLR3MLZ" }
    }, router);
}

import firebaseMessaging from "./utils/fcm.js"
Vue.prototype.$fcmMessaging = firebaseMessaging

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    acl,
    render: h => h(App)
}).$mount('#app')
