<!-- =========================================================================================
    Description: Single File Selection Component
     Author: @letstream
    Events: 
    - @error
    - @success
    Props:
    -extensions : An array defining allowed file extensions
    Returning array with file details
========================================================================================== -->
<template>
    <label 
        class="file-select"
        :class="warning ? 'file-error' : null"
    >
        <div class="select-button" v-if="!download_mode">
            <span v-if="!file && !readonly">Select File</span>
            <span v-else>
                <template v-if="typeof file == 'string'">
                    Already Uploaded.
                </template>
                <template v-else>
                    <template v-if="readonly">
                        No File Uploaded
                    </template>
                    <template v-else>
                        <template v-if="file">
                            {{ file.name }}
                        </template>
                        <template v-else>
                            Unknown Error
                        </template>
                    </template>
                </template>
            </span>
        </div>
        <template v-else>
            <a :href="file" target="_blank" v-if="typeof file == 'string'" >
                <div class="select-button" >
                   <span>Download File</span>
                </div>
            </a>
            <div class="select-button" v-else>
                <span>No File Uploaded</span>
            </div>
        </template>
        <span v-if="!download_mode && typeof file == 'string'" style="display:block">
            <a :href="file" target="_blank">Click here</a> to download existing file.
        </span>
        <span v-if="warning && !readonly" class="display:block">
            {{warning_text}}
        </span>
        <input type="file" @change="handleFileChange" :accept="extensions_parsed.join(',')"  v-if="!readonly && !download_mode"/>
    </label>
</template>
<script>

export default {
    props: {
        value: {
            required: true,
        },
        extensions : {
            type: Array,
            default: () => []
        },
        warning : {
            type: Boolean,
            default: false,
        },
        warning_text : {
            default: 'Error in this field'
        },
        readonly: {
            type: Boolean,
            default: false
        },
        download_mode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            file: this.value,
            extensions_parsed: []
        }
    },
    mounted() {
        this.file = this.value
        this.extensions.forEach((v, i) =>{ 
            if(v == 'zip'){
                this.extensions_parsed.push("application/x-zip-compressed")
            } else if (v == 'jpeg' || v == 'jpg') {
                this.extensions_parsed.push("image/jpeg")
            } else if (v == 'png') {
                this.extensions_parsed.push("image/png")
            } else if (v == 'xls' || v == 'xlsx') {
                this.extensions_parsed.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            } else if (v == 'pdf') {
                this.extensions_parsed.push("application/pdf")
            }
        })
    },
    watch: {
        value(newV, oldV) {
            this.file = newV
        }
    },
    methods: {
        handleFileChange(e){
            let file = e.target.files[0];
            this.file = file
            let file_extension = file.type;
            let error = (this.extensions_parsed.includes(file_extension))?false:true

            if(this.extensions_parsed.length && error){
                this.$vs.notify({
                    time: 2500,
                    title: 'Error',
                    text: "Please choose a valid file. Only extensions " + this.extensions.join(", ") + " are supported.",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            } else{
                this.$emit('update:value', file);
            }
        },
    }
}
</script>

<style lang="scss">
    @import "../../../../assets/scss/vuesax/variables";
    .file-select .select-button {
        margin-top: 4px;
        padding: 0.45rem;
        color: $primary;
        background-color: transparent;
        border: 1px solid $primary;
        border-radius: .3rem;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        transition: 0.3s all;
    }
    .file-error{
        border-color: $danger!important;
    }
    .file-select > .select-button:hover {
        background-color: $primary;
        color: white;
    }
    .file-select > input[type="file"] {
        display: none;
    }

</style>
